// import * as React from 'react'
// import Box from '@mui/material/Box'
// import Stepper from '@mui/material/Stepper'
// import Step from '@mui/material/Step'
// import StepLabel from '@mui/material/StepLabel'

// const steps = [
//   'pending',
//   'processing',
//   'shipped',
//   'delivered',
//   'returned',
//   'refunded',
// ]

// export default function ProductShippingSteps({ step = 0 }) {
//   return (
//     <Box sx={{ width: '100%' }} className='py-5'>
//       <Stepper activeStep={step} alternativeLabel>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   )
// }

import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material'
import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'

function ProductShippingSteps({ step, processingTimestamps }) {
  const steps = [
    'Pending',
    'Processing',
    'Shipped',
    'Delivered',
    'Returned',
    'Refunded',
  ]

  // Use media query to determine the screen size
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Box sx={{ width: '100%', marginBottom: 3 }}>
      <Stepper
        activeStep={step}
        alternativeLabel={!isMobile}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {label}
              {processingTimestamps &&
                processingTimestamps[label.toLowerCase()] && (
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    display='block'
                  >
                    {formatDateTimeWithAMAndPM(
                      processingTimestamps[label.toLowerCase()]
                    )}
                  </Typography>
                )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default ProductShippingSteps
