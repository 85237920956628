// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
// import * as api from '../../utils/api/apiClient'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select'
// import { FaSave } from 'react-icons/fa'
// import { calculateDiscountPrice } from '../../utils/PriceCalculate'
// import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'
// import Loading from '../../components/Loading'
// import { Button } from '@mui/material'
// import { toast } from 'react-toastify'
// import { Helmet } from 'react-helmet'
// import OrderStatusPopup from '../../components/ReUseFunctionComponent/OrderStatusPopup'
// import ProductShippingSteps from '../../components/ProductComponent/ProductShippingSteps'

// function OrderDetail() {
//   const { id } = useParams()
//   const [loading, setLoading] = useState(false)
//   const [refresh, setRefresh] = useState(false)
//   const [data, setData] = useState({})
//   const [orderForShippingPopup, setOrderForShippingPopup] = useState(false)
//   const [orderSummary, setOrderSummary] = useState({
//     subtotal: '00',
//     discount: '00',
//     shipping: '00',
//     total: '00',
//   })
//   const [selectedStatus, setSelectedStatus] = useState({
//     status: '',
//   })
//   const status = [
//     'pending',
//     'processing',
//     'shipped',
//     'delivered',
//     'returned',
//     'refunded',
//   ]

//   console.log(data)
//   const handleChange = (event) => {
//     setSelectedStatus({ ...selectedStatus, status: event.target.value })
//   }

//   const changeOrderStatus = async (event) => {
//     if (selectedStatus?.status === 'shipped') {
//       setOrderForShippingPopup(true)
//     }
//     setLoading(true)
//     event.preventDefault()
//     try {
//       const res = await api.orderStatusUpdate(id, selectedStatus)
//       if (res) {
//         setRefresh(!refresh)
//         setLoading(false)
//       }
//     } catch (error) {
//       setLoading(false)
//       toast.error(error?.response?.data?.message)
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     const getOrderDetail = async () => {
//       setLoading(true)
//       try {
//         const res = await api.getOneOrder(id)
//         if (res) {
//           setData(res.data.order)
//           setSelectedStatus({
//             ...selectedStatus,
//             status: res.data?.order?.order_status,
//           })

//           if (res?.data?.order?.order_item) {
//             let subtotalVal = 0
//             let discountVal = 0
//             res?.data?.order.order_item?.forEach((element) => {
//               subtotalVal = subtotalVal + element.price * element.quantity
//               discountVal = discountVal + element.discount
//             })
//             setOrderSummary({
//               ...orderSummary,
//               discount: discountVal,
//               subtotal: subtotalVal,
//               total:
//                 calculateDiscountPrice(subtotalVal, discountVal) +
//                 orderSummary.shipping * 1,
//             })
//           }
//           setLoading(false)
//         }
//       } catch (error) {
//         setLoading(false)
//         console.log(error)
//       }
//     }
//     getOrderDetail()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [id, refresh])

//   return (
//     <>
//       <Helmet>
//         <title>Order Detail | Inflection Admin</title>
//       </Helmet>
//       {loading && <Loading />}
//       {orderForShippingPopup && (
//         <OrderStatusPopup
//           handleClose={() => setOrderForShippingPopup(false)}
//           isOpen={orderForShippingPopup}
//           orderId={id}
//           pageRefresh={() => setRefresh(!refresh)}
//         />
//       )}
//       {/* <div className="flex justify-center items-center w-full h-screen md:hidden">
//         <div className="text-center">
//           <h1 className="text-2xl font-semibold">Order Detail</h1>
//           <p>Showing on desktop</p>
//         </div>
//       </div> */}
//       <div>
//         <ProductShippingSteps step={0} />
//       </div>
//       <div className='flex items-center justify-center my-3'>
//         <div className='flex flex-col gap-10 w-[768px] overflow-auto rounded-lg bg-white p-5'>
//           <div className='w-full'>
//             <div className='flex justify-between gap-5 font-semibold text-2xl'>
//               <h1>Order</h1>
//               <h3>Customer</h3>
//             </div>
//             <div className='flex justify-between gap-5'>
//               <div>
//                 <h2 className='font-medium text-mediumgray text-sm'>Date</h2>
//                 <p className='font-medium'>
//                   {formatDateTimeWithAMAndPM(data?.order_at)}
//                 </p>
//               </div>
//               <div>
//                 <h2 className='font-medium text-mediumgray text-sm'>
//                   Reference
//                 </h2>
//                 <p>{`#${data?.order_code}`}</p>
//               </div>
//               <div className='font-medium text-end'>
//                 <p className='capitalize'>{data?.order_customer_name}</p>
//                 <p className='font-normal lowercase italic underline text-blue'>
//                   {data?.customer_email}
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className='grid grid-cols-2 items-center'>
//             <div>
//               <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
//                 <InputLabel id='demo-simple-select-standard-label'>
//                   Status
//                 </InputLabel>

//                 <Select
//                   labelId='demo-simple-select-standard-label'
//                   id='demo-simple-select-standard'
//                   value={selectedStatus.status}
//                   onChange={handleChange}
//                   label='Status'
//                   className='capitalize'
//                 >
//                   <MenuItem value=''>
//                     <em>None</em>
//                   </MenuItem>
//                   {status.map((item, index) => (
//                     <MenuItem key={index} value={item} className='capitalize'>
//                       {item}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div className='font-medium text-base text-end'>
//               <h1 className='font-medium text-xl'>Shipping Address</h1>
//               <p className='text-wrap italic font-normal'>{data?.address}</p>
//             </div>
//           </div>

//           <div>
//             <div className='border border-gray-200 p-4 rounded-lg space-y-4'>
//               <div className='hidden sm:grid sm:grid-cols-5'>
//                 <div className='sm:col-span-2 text-xs font-medium  uppercase'>
//                   Item
//                 </div>
//                 <div className='text-start text-xs font-medium  uppercase'>
//                   Quantity
//                 </div>
//                 <div className='text-start text-xs font-medium  uppercase'>
//                   Discount
//                 </div>
//                 <div className='text-end text-xs font-medium  uppercase'>
//                   Amount
//                 </div>
//               </div>

//               <div className='hidden sm:block border-b'></div>
//               {data?.order_item?.map((element, i) => (
//                 <div key={i} className='grid grid-cols-3 sm:grid-cols-5 gap-2'>
//                   <div className='col-span-full sm:col-span-2'>
//                     <h5 className='sm:hidden text-xs font-medium text-gray-500 uppercase'>
//                       Item
//                     </h5>
//                     <p className='font-medium text-gray-800'>
//                       {element.product_name}
//                     </p>
//                   </div>
//                   <div>
//                     <h5 className='sm:hidden text-xs font-medium text-gray-500 uppercase'>
//                       Qty
//                     </h5>
//                     <p className='text-gray-800'>{element.quantity}</p>
//                   </div>
//                   <div>
//                     <h5 className='sm:hidden text-xs font-medium text-gray-500 uppercase'>
//                       Discount
//                     </h5>
//                     <p className='text-gray-800'>{`${element.discount}%`}</p>
//                   </div>
//                   <div>
//                     <h5 className='sm:hidden text-xs font-medium text-gray-500 uppercase'>
//                       Amount
//                     </h5>
//                     <p className='sm:text-end text-gray-800'>
//                       {`₹${element.price}`}
//                     </p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className='mt-5 flex sm:justify-end'>
//               <div className='w-full max-w-2xl sm:text-end space-y-2'>
//                 <div className='grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2'>
//                   <dl className='grid sm:grid-cols-5 gap-x-3'>
//                     <dt className='col-span-3 font-semibold text-gray-800'>
//                       Subtotal:
//                     </dt>
//                     <dd className='col-span-2 text-gray-500'>
//                       {`₹${orderSummary.subtotal}`}
//                     </dd>
//                   </dl>

//                   <dl className='grid sm:grid-cols-5 gap-x-3'>
//                     <dt className='col-span-3 font-semibold text-gray-800'>
//                       Total Discount
//                     </dt>
//                     <dd className='col-span-2 text-gray-500'>
//                       {`${orderSummary.discount}%`}
//                     </dd>
//                   </dl>

//                   <dl className='grid sm:grid-cols-5 gap-x-3'>
//                     <dt className='col-span-3 font-semibold text-gray-800'>
//                       Shipping
//                     </dt>
//                     <dd className='col-span-2 text-gray-500'>
//                       {`₹${orderSummary.shipping}`}
//                     </dd>
//                   </dl>

//                   <dl className='grid sm:grid-cols-5 gap-x-3'>
//                     <dt className='col-span-3 font-semibold text-gray-800'>
//                       Amount paid:
//                     </dt>
//                     <dd className='col-span-2 text-gray-500'>
//                       {`₹${orderSummary.total}`}
//                     </dd>
//                   </dl>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className='text-end'>
//             <Button
//               onClick={changeOrderStatus}
//               variant='contained'
//               startIcon={<FaSave />}
//             >
//               Save
//             </Button>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default OrderDetail

// *********************************2*********************************************
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as api from '../../utils/api/apiClient'
import { FaSave } from 'react-icons/fa'
import { calculateDiscountPrice } from '../../utils/PriceCalculate'
import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'
import Loading from '../../components/Loading'
import { Button, Typography, Box, Paper, Grid, Divider } from '@mui/material'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import OrderStatusPopup from '../../components/ReUseFunctionComponent/OrderStatusPopup'
import ProductShippingSteps from '../../components/ProductComponent/ProductShippingSteps'
import ShippedInvoice from '../../components/ProductComponent/ShippedInvoice'
import Invoice from '../../components/ProductComponent/Invoice'

function OrderDetail() {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [data, setData] = useState(null)
  const [isInvoice, setIsInvoice] = useState(false)
  const [isInvoiceMain, setIsInvoiceMain] = useState(false)

  const [orderForShippingPopup, setOrderForShippingPopup] = useState(false)
  const [orderSummary, setOrderSummary] = useState({
    subtotal: '00',
    discount: '00',
    shipping: '00',
    total: '00',
  })

  // Define the status progression order
  const statusProgression = [
    'pending',
    'processing',
    'shipped',
    'delivered',
    'returned',
    'refunded',
  ]

  const showInvoiceStatuses = ['delivered', 'returned', 'refunded']
  const Show = () => {
    setIsInvoice((isInvoice) => !isInvoice)
  }
  const ShowInvoice = () => {
    setIsInvoiceMain((isInvoiceMain) => !isInvoiceMain)
  }
  console.log(data)
  const changeOrderStatus = async (event, isNextStep = false) => {
    setLoading(true)
    event.preventDefault()

    try {
      // Find current status index
      const currentStatusIndex = statusProgression.indexOf(data?.order_status)

      // Determine next status
      let nextStatus = data?.order_status
      if (isNextStep && currentStatusIndex < statusProgression.length - 1) {
        nextStatus = statusProgression[currentStatusIndex + 1]
      }

      // Prepare status update
      const statusUpdate = { status: nextStatus }

      // Special handling for 'shipped' status
      if (nextStatus === 'shipped') {
        setOrderForShippingPopup(true)
      }

      // if (nextStatus === 'shipped') {
      //   // Agar order status pehle se 'shipped' nahi hai
      //   if (data.order_status !== 'shipped') {
      //     // Popup dikhane ke liye flag set kiya
      //     setOrderForShippingPopup(true)
      //     return // Aage ki processing rokdi
      //   }
      // }

      // Update order status
      const res = await api.orderStatusUpdate(id, statusUpdate)
      if (res) {
        setRefresh(!refresh)
        toast.success('Order status updated successfully')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.error('Change Order Status Error:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const getOrderDetail = async () => {
      setLoading(true)
      try {
        const res = await api.getOneOrder(id)
        // console.log('Full API Response:', res) // Detailed logging

        if (res && res.data && res.data.order) {
          const orderData = res.data.order
          // console.log('Order Data:', orderData) // Log order data
          setData(orderData)

          // Calculate order summary
          if (orderData.order_item) {
            let subtotalVal = 0
            let discountVal = 0
            orderData.order_item.forEach((element) => {
              subtotalVal = subtotalVal + element.price * element.quantity
              discountVal = discountVal + element.discount
            })

            setOrderSummary({
              discount: discountVal,
              subtotal: subtotalVal,
              shipping: orderData.shipping_charge || '00', // Add shipping charge
              total:
                calculateDiscountPrice(subtotalVal, discountVal) +
                (orderData.shipping_charge || 0),
            })
          }
        } else {
          console.error('No order data received')
          toast.error('Unable to fetch order details')
        }
      } catch (error) {
        console.error('Get Order Detail Error:', error)
        toast.error('Error fetching order details')
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      getOrderDetail()
    }
  }, [id, refresh])

  // Function to get processing timestamp for current status
  const getProcessingTimestamp = () => {
    if (!data) return null

    const statusToTimestampMap = {
      pending: data.pending_at,
      processing: data.processing_at,
      shipped: data.shipped_at,
      delivered: data.delivered_at,
      returned: data.returned_at,
      refunded: data.refunded_at,
    }
    return statusToTimestampMap[data.order_status] || null
  }

  if (loading) return <Loading />
  if (!data) return <Typography variant='h6'>No Order Details Found</Typography>

  return (
    <>
      <Helmet>
        <title>Order Detail | Inflection Admin</title>
      </Helmet>

      {orderForShippingPopup && (
        <OrderStatusPopup
          handleClose={() => setOrderForShippingPopup(false)}
          isOpen={orderForShippingPopup}
          orderId={id}
          pageRefresh={() => setRefresh(!refresh)}
          processingAt={getProcessingTimestamp()}
        />
      )}

      <Box sx={{ padding: 3 }}>
        <ProductShippingSteps
          step={statusProgression.indexOf(data?.order_status)}
          processingTimestamps={{
            pending: data?.pending_at,
            processing: data?.processing_at,
            shipped: data?.shipped_at,
            delivered: data?.delivered_at,
            returned: data?.returned_at,
            refunded: data?.refunded_at,
          }}
        />

        <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
          {/* Order Header */}
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='h5' fontWeight='bold'>
                Order #{data.order_code}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                Ordered on {formatDateTimeWithAMAndPM(data?.order_at)}
              </Typography>
            </Grid>
            <Grid item textAlign='right'>
              <Typography variant='h6' color='primary'>
                {data.order_customer_name}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {data.customer_email}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Order Items */}
          {/* <Box>
            <Typography variant='h6' gutterBottom>
              Order Items
            </Typography>
            {data?.order_item?.map((item, index) => (
              <Grid container key={index} spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                  <Typography variant='body1'>{item.product_name}</Typography>
                </Grid>
                <Grid item xs={2} textAlign='center'>
                  <Typography variant='body2'>Qty: {item.quantity}</Typography>
                </Grid>
                <Grid item xs={2} textAlign='center'>
                  <Typography variant='body2'>
                    Discount: {item.discount}%
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign='right'>
                  <Typography variant='body1'>₹{item.price}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box> */}
          <Box>
            <Typography variant='h6' gutterBottom>
              Order Items
            </Typography>
            {data?.order_item?.map((item, index) => (
              <Grid container key={index} spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1'>
                    Name:{item.product_name}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    <span className='font-bold'> Variant Name:</span>{' '}
                    {item.variant_name}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    <span className='font-bold'>Code:</span>{' '}
                    {item.variant_product_code}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className='line-clamp-2'
                    sx={{ mt: 1 }}
                    dangerouslySetInnerHTML={{
                      __html: item.variant_description,
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={2} textAlign='center'>
                  <Typography variant='body2'>Qty: {item.quantity}</Typography>
                </Grid>
                <Grid item xs={4} sm={2} textAlign='center'>
                  <Typography variant='body2'>
                    Discount: {item.discount}%
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} textAlign='right'>
                  <Typography variant='body1'>₹{item.price}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Order Summary */}
          <Box textAlign='right'>
            <Grid container justifyContent='flex-end' spacing={1}>
              <Grid item xs={8}>
                <Typography variant='body1'>Subtotal:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>
                  ₹{orderSummary.subtotal}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1'>Total Discount:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>
                  {orderSummary.discount}%
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1'>Shipping:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>
                  ₹{orderSummary.shipping}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='h6' fontWeight='bold'>
                  Total Amount:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h6' color='primary' fontWeight='bold'>
                  ₹{orderSummary.total}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Shipping Address */}
          <Box>
            <Typography variant='h6' gutterBottom>
              Shipping Address
            </Typography>
            <Typography variant='body1'>{data.address}</Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Action Buttons */}
          <Box
            display='flex'
            justifyContent='end'
            className='gap-4'
            marginTop={2}
          >
            {data.order_status === 'shipped' && (
              <Button
                onClick={() => Show()}
                variant='contained'
                color='primary'
                startIcon={<FaSave />}
              >
                Shipped Invoice
              </Button>
            )}{' '}
            {showInvoiceStatuses.includes(data.order_status) && (
              <Button
                onClick={() => ShowInvoice()}
                variant='contained'
                color='primary'
                startIcon={<FaSave />}
              >
                Invoice
              </Button>
            )}
            <Button
              onClick={(e) => changeOrderStatus(e, true)}
              variant='contained'
              color='primary'
              startIcon={<FaSave />}
            >
              Next & Save
            </Button>
          </Box>
        </Paper>
      </Box>

      {isInvoice && (
        <div className='fixed overflow-y-auto min-h-screen inset-0 z-50 bg-black/70 md:flex md:justify-center'>
          <div className='flex'>
            <div>
              <ShippedInvoice
                data={data}
                // orderSummary={orderSummary}
                onClick={() => setIsInvoice(false)}
              />
            </div>
          </div>
        </div>
      )}
      {isInvoiceMain && (
        <div className='fixed overflow-y-auto min-h-screen inset-0 z-50 bg-black/70 md:flex md:justify-center'>
          <div className='flex'>
            <div>
              <Invoice
                data={data}
                orderSummary={orderSummary}
                onClick={() => setIsInvoiceMain(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrderDetail
// ***********************************************************3*******************************************
