import React from 'react'
// import Image from 'next/image'
// import logo from '../../../public/logo.jpg'
// import LocalTime from './LocalTime'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { MdOutlineFileDownload } from 'react-icons/md'
import { IoCloseSharp } from 'react-icons/io5'
import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'

function Invoice({ data, orderSummary, onClick }) {
  const downloadPDF = (url = 'invoice') => {
    const input = document.getElementById('pdfContent')

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const imgWidth = 210 // Width of A4 in mm
        const pageHeight = 297 // Height of A4 in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }

        pdf.save(`${url}.pdf`)
      })
      .catch((err) => {
        console.error('Error generating PDF:', err)
      })
  }

  return (
    <>
      <div
        className='max-w-[85rem] px-4 bg-white sm:px-2 lg:px-4 mx-auto'
        id='pdfContent'
      >
        <div className='sm:w-11/12 lg:w-3/4 mx-auto'>
          <div className='flex flex-col p-4 sm:p-10 rounded-xl'>
            <div className='flex justify-between'>
              <div>
                {/* <img
                  src={logo}
                  width={50}
                  height={50}
                  alt='logo'
                  className='rounded-full'
                /> */}
                <h1 className='text-lg'>
                  <span className='font-bold'>Rainbow</span>Rug Art
                </h1>
              </div>

              <div className='text-end'>
                <h2 className='text-2xl md:text-3xl font-semibold '>
                  Invoice #
                </h2>
                <span className='mt-1 block'>{data.order_code}</span>

                <p className='mt-4 not-italic'>
                  Abhishek Singh Carpet Plant
                  <br />
                  6GV6+PCH, Bhajaipur, Khamaria
                  <br />
                  Bhawanath Patti, Uttar Pradesh
                  <br />
                  221306
                  <br />
                </p>
              </div>
            </div>
            <div className='mt-8 grid sm:grid-cols-2 gap-3'>
              <div>
                <h3 className='text-lg font-semibold'>Bill to:</h3>
                <p className='text-lg font-semibold'>
                  {data.order_customer_name}
                </p>
                <p>{data.order_customer_phone}</p>
                <p className='not-italic'>{data.address}</p>
              </div>

              <div className='sm:text-end space-y-2'>
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2'>
                  <dl className='grid sm:grid-cols-5 gap-x-3'>
                    <dt className='col-span-3 font-semibold'>Invoice date:</dt>
                    <dd className='col-span-2 text-sm italic'>
                      {formatDateTimeWithAMAndPM(data.order_at)}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className='mt-6'>
              <div className='border border-gray-200 p-4 rounded-lg space-y-4'>
                <div className='hidden sm:grid sm:grid-cols-5'>
                  <div className='sm:col-span-2 text-xs font-medium  uppercase'>
                    Item
                  </div>
                  <div className='text-start text-xs font-medium  uppercase'>
                    Quantity
                  </div>

                  <div className='text-start text-xs font-medium  uppercase'>
                    Discount
                  </div>
                  <div className='text-end text-xs font-medium  uppercase'>
                    Amount
                  </div>
                </div>

                <div className='hidden sm:block border-b'></div>
                {data?.order_item?.map((element, i) => (
                  <div
                    key={i}
                    className='grid grid-cols-3 sm:grid-cols-5 gap-2'
                  >
                    <div className='col-span-full sm:col-span-2'>
                      <h5 className='sm:hidden text-xs font-medium text-black uppercase'>
                        Item
                      </h5>
                      <p className='font-medium text-black'>
                        {element.product_name}
                      </p>
                      <p className='text-sm text-gray-700'>
                        Variant: {element.variant_name}
                      </p>
                      <p className='text-sm text-gray-700'>
                        Code: {element.variant_product_code}
                      </p>
                      <p
                        className='text-sm text-gray-700 line-clamp-2'
                        sx={{ mt: 1 }}
                        dangerouslySetInnerHTML={{
                          __html: element.variant_description,
                        }}
                      ></p>
                    </div>
                    <div>
                      <h5 className='sm:hidden text-xs font-medium text-black uppercase'>
                        Qty
                      </h5>
                      <p className='text-black'>{element.quantity}</p>
                    </div>
                    <div>
                      <h5 className='sm:hidden text-xs font-medium text-black uppercase'>
                        Discount
                      </h5>
                      <p className='text-black'>{`${element.discount}%`}</p>
                    </div>
                    <div>
                      <h5 className='sm:hidden text-xs font-medium text-black uppercase'>
                        Amount
                      </h5>
                      <p className='sm:text-end text-black'>
                        {`₹${element.price}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='mt-8 flex sm:justify-end'>
              <div className='w-full max-w-2xl sm:text-end space-y-2'>
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2'>
                  <dl className='grid sm:grid-cols-5 gap-x-3'>
                    <dt className='col-span-3 font-semibold text-black'>
                      Subtotal:
                    </dt>
                    <dd className='col-span-2 text-black'>
                      {`₹${orderSummary.subtotal}`}
                    </dd>
                  </dl>

                  <dl className='grid sm:grid-cols-5 gap-x-3'>
                    <dt className='col-span-3 font-semibold text-black'>
                      Total Discount
                    </dt>
                    <dd className='col-span-2 text-black'>
                      {`${orderSummary.discount}%`}
                    </dd>
                  </dl>

                  <dl className='grid sm:grid-cols-5 gap-x-3'>
                    <dt className='col-span-3 font-semibold text-black'>
                      Shipping
                    </dt>
                    <dd className='col-span-2 text-black'>
                      {`₹${orderSummary.shipping}`}
                    </dd>
                  </dl>

                  <dl className='grid sm:grid-cols-5 gap-x-3'>
                    <dt className='col-span-3 font-semibold text-black'>
                      Amount paid:
                    </dt>
                    <dd className='col-span-2 text-black'>
                      {`₹${orderSummary.total}`}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className='mt-8 sm:mt-12'>
              <h4 className='text-lg font-semibold text-black'>Thank you!</h4>
              <p className='text-black'>
                If you have any questions concerning this invoice, use the
                following contact information:
              </p>
              <div className='mt-2'>
                <p className='block text-sm font-medium text-black'>
                  contactus@rainbowrugart.com
                </p>
                <p className='block text-sm font-medium text-black'>
                  +7985180648
                </p>
              </div>
            </div>

            <p className='mt-5 text-sm text-black'>
              © 2024 Rainbow Rug Art All Rights Reserve. This invoice design &
              developed by{' '}
              <a href='https://inflection.org.in?ref=rainbowrugart.com'>
                Inflection ORG
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className='my-2 flex justify-center gap-8'>
        <button
          onClick={() => {
            downloadPDF(data.order_code)
          }}
          className='py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border font-medium bg-white text-black shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm'
        >
          <MdOutlineFileDownload className='size-5' />
          Invoice PDF
        </button>
        <button
          onClick={onClick}
          className='py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border font-medium bg-white text-black shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm'
        >
          <IoCloseSharp className='size-5' />
          close
        </button>
      </div>
    </>
  )
}

export default Invoice
